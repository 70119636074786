import { createSlice } from "@reduxjs/toolkit";
import {
  fetchNoticesThunk,
  createNoticeThunk,
  updateNoticeThunk,
  deleteNoticeThunk,
  fetchNoticeUsersThunk, // import the new thunk
} from "./noticeThunks";

const initialState = {
  notices: [],
  noticeUsers: [], // New: stores the fetched notice users
  selectedNotice: null,
  editMode: false,
  loading: false,
  error: null,
  titleToDelete: "",
  currentPage: 1, // current page number
  totalPages: 1, // total pages available
  totalNotices: 0, // total count of notices
};

const noticeSlice = createSlice({
  name: "notice",
  initialState,
  reducers: {
    setSelectedNotice: (state, action) => {
      state.selectedNotice = action.payload;
    },
    setEditMode: (state, action) => {
      state.editMode = action.payload;
    },
    resetEditMode: (state) => {
      state.editMode = false;
      state.selectedNotice = null;
    },
    setTitleToDelete: (state, action) => {
      state.titleToDelete = action.payload;
    },
    resetTitleToDelete: (state) => {
      state.titleToDelete = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Notices
      .addCase(fetchNoticesThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNoticesThunk.fulfilled, (state, action) => {
        state.notices = action.payload?.notices || [];
        state.currentPage = action.payload?.currentPage || 1;
        state.totalPages = action.payload?.totalPages || 1;
        state.totalNotices = action.payload?.totalNotices || 0;
        state.loading = false;
      })
      .addCase(fetchNoticesThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      // Create Notice
      .addCase(createNoticeThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNoticeThunk.fulfilled, (state, action) => {
        state.notices.push(action.payload);
        state.loading = false;
      })
      .addCase(createNoticeThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      // Update Notice
      .addCase(updateNoticeThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateNoticeThunk.fulfilled, (state, action) => {
        const index = state.notices.findIndex(
          (notice) => notice._id === action.payload._id
        );
        if (index !== -1) {
          state.notices[index] = action.payload;
        }
        state.loading = false;
      })
      .addCase(updateNoticeThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      // Delete Notice
      .addCase(deleteNoticeThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteNoticeThunk.fulfilled, (state, action) => {
        state.notices = state.notices.filter(
          (notice) => notice._id !== action.payload
        );
        state.loading = false;
      })
      .addCase(deleteNoticeThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      // New: Fetch Notice Users
      .addCase(fetchNoticeUsersThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNoticeUsersThunk.fulfilled, (state, action) => {
        state.noticeUsers = action.payload || [];
        state.loading = false;
      })
      .addCase(fetchNoticeUsersThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

// Export actions
export const {
  setSelectedNotice,
  setEditMode,
  resetEditMode,
  setTitleToDelete,
  resetTitleToDelete,
} = noticeSlice.actions;

export default noticeSlice.reducer;
