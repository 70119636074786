import React from "react";

const FormInput = ({
  id,
  label,
  type = "text",
  value,
  onChange,
  required = false,
  readOnly,
  maxlength
}) => {
  return (
    <div className="mt-4">
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <input
        type={type}
        name={id}
        id={id}
        value={value}
        onChange={onChange}
        required={required}
        readOnly={readOnly?readOnly:false}
        maxlength={maxlength||1000}
        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
    </div>
  );
};

export default FormInput;
